.landing-page {
    background: 
    linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.6)), 
    url('../assets/landing2.jpeg') no-repeat center center/cover;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 2rem;
    background-position: bottom;
}
.top-center-logo {
    position: absolute;
    top: 14%;
    left: 52%;
    transform: translateX(-50%);
    max-width: 300px; /* Adjust size as needed */
    max-height: 200px;
}
.text-container h1 {
    font-family: poppins ;
    color: yellow;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 1);
    font-size: 2.5rem;
    margin-bottom: 1rem;
    transform: 
        perspective(500px) 
        rotateX(75deg)  
        rotateZ(-20deg)
        translateX(-240px)
        translateY(320px);
    transform-origin: bottom left;
   
}

.text-container .tagline {
    color: yellow;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
    transform: 
    perspective(500px) 
    rotateX(75deg)  
    rotateZ(-20deg)
    translateX(-220px)
    translateY(310px);
        
    transform-origin: bottom left;
}
.sign-in {
    color: yellow;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 4);
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    transform: 
    perspective(500px) 
    rotateX(75deg)  
    rotateZ(-20deg)
    translateX(-100px)
    translateY(330px);
        
    transform-origin: bottom left;
    transition-duration: 1s;
}
.sign-in:hover {
    text-shadow: 16px 16px 2px rgba(0, 0, 0, 0.8);
    transform: 
    perspective(500px) 
    rotateX(75.7deg)  
    rotateZ(-20deg)
    translateX(-100px)
    translateY(330px);
}
/* //fast reliable affordable wifi,self-contained, academic atomsphere, our rooms/ -spacious rooms, student safety, clean and green relaxation area, Perks. spacious laundry area, 
limited hot water for early rises,  realiable water supply, tv rooms
 */
.styled-navlink {
    text-decoration: none;
}

.styled-navlink:hover {
    background-color: yellow;
    color: black;
}
@media (min-width: 1550px) {
    .text-container h1 {
        font-size: 2rem;
            transform: 
            perspective(500px) 
            rotateX(75deg)  
            rotateZ(-30deg)
            translateX(-275px)
            translateY(320px);
        }
        .text-container .tagline {
            font-size: .6rem;
            transform: 
            perspective(500px) 
            rotateX(75deg)  
            rotateZ(-30deg)
            translateX(-260px)
            translateY(320px);
        }
        .sign-in {
           
            transform: 
            perspective(500px) 
            rotateX(75deg)  
            rotateZ(-10deg)
            translateX(10px)
            translateY(380px);
        }
        .sign-in:hover {
            text-shadow: 16px 16px 2px rgba(0, 0, 0, 0.8);
            transform: 
            perspective(500px) 
            rotateX(75deg)  
            rotateZ(-10deg)
            translateX(11px)
            translateY(380px);
        }
}

  
@media (max-width: 768px) {
    .landing-page{
        overflow: hidden;
    }
    .top-center-logo {
        top: 12%;
        left: 55%;
    
    }
    .text-container h1 {
    font-size: 1rem;
        transform: 
        perspective(500px) 
        rotateX(75deg)  
        rotateZ(-44deg)
        translateX(-265px)
        translateY(260px);
    }
    .text-container .tagline {
        font-size: .3rem;
        transform: 
        perspective(500px) 
        rotateX(75deg)  
        rotateZ(-44deg)
        translateX(-260px)
        translateY(262px);
    }
    .styled-navlink {
        font-size: 1.5rem;

    }
    .sign-in {
        border: 1px solid yellow;
        width: 70px;
        padding: 8px;
        border-radius: 10px;
        font-size: 1rem;
        transform: 
        perspective(0px) 
        rotateX(0deg)  
        rotateZ(0deg)
        translateX(150px)
        translateY(250px);
    }
}
