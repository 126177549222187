@media (min-width: 992px) {
    .main-content {
      margin-left: 250px; /* Matches the width of the sidebar */
    }
  }
  
  /* General main content styling */
  .main-content {
    padding: 1rem; /* Add padding to give space around content */
  }
  