.placeholder-image {
    border: 2px dashed #007bff;
    border-radius: 5px;
    padding: 20px;
    color: #007bff;
}

.preview-img {
    max-width: 100%;
    max-height: 200px;
    border-radius: 5px;
}