.gallery-item {
    position: relative;
    overflow: hidden;
}

.gallery-item img {
    transition: transform 0.5s ease;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.carousel-item img {
    height: 400px; /* Set a fixed height for images */
    object-fit: cover; /* Maintain aspect ratio */
}