/* ChatbotPlugin.css */
.chatbot-container {
    position: fixed;
    z-index: 1000;
  }
  
  .chatbot-toggle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .chatbot-toggle:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .chatbot-dialog {
    position: absolute;
    bottom: 80px;
    right: 0;
    width: 350px;
    height: 500px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid;
    background-color: #fff;
  }
  
  .chatbot-header {
    padding: 15px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .chatbot-header h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .chatbot-messages {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .chatbot-message {
    max-width: 80%;
    padding: 10px 15px;
    border-radius: 18px;
    margin-bottom: 5px;
    word-wrap: break-word;
    position: relative;
  }
  
  .bot-message {
    align-self: flex-start;
    border-bottom-left-radius: 5px;
  }
  
  .user-message {
    align-self: flex-end;
    border-bottom-right-radius: 5px;
  }
  
  .error-message {
    background-color: #fdeded !important;
    color: #5f2120 !important;
    border-left: 3px solid #ef5350;
  }
  
  .message-text {
    margin-bottom: 4px;
    line-height: 1.4;
  }
  
  .message-timestamp {
    font-size: 10px;
    opacity: 0.7;
    text-align: right;
  }
  
  .chatbot-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .chatbot-input textarea {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 10px 15px;
    font-size: 14px;
    resize: none;
    outline: none;
    max-height: 100px;
    font-family: inherit;
  }
  
  .chatbot-input textarea:focus {
    border-color: #4285F4;
  }
  
  .chatbot-input button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .chatbot-input button:disabled {
    cursor: not-allowed;
  }
  
  /* Loading animation */
  .loading-message {
    background-color: #f1f3f4 !important;
    padding: 10px !important;
  }
  
  .typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .typing-indicator span {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #888;
    display: inline-block;
    margin: 0 2px;
    animation: typing-bounce 1.4s infinite ease-in-out;
  }
  
  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes typing-bounce {
    0%, 80%, 100% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .chatbot-dialog {
      width: 300px;
      right: 0;
      bottom: 70px;
    }
  }
  
  @media (max-width: 480px) {
    .chatbot-dialog {
      width: calc(100vw - 50px);
      height: 60vh;
      right: 5px;
      bottom: 70px;
    }
    
    .chatbot-toggle {
      width: 50px;
      height: 50px;
      font-size: 20px;
      
    }
  }