
/* For larger screens, add left margin to main content to prevent overlap */

  .building {
    border: 2px solid #007bff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    background-color: #f8f9fa;
}

.row{
  /* display:none
   */
   margin:auto;

   justify-content: center;
}


@media (min-width: 992px) {
  .logo-image {
    width: 200px;
    height: 120px;
  }
}

/* Bottom tab for small screens */
@media (max-width: 991px) {

  .logo-image {
    width: 100px;
    height: 10vh;
  }
  



}
