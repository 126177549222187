/* PostList.css */

.container {
  font-family: 'Poppins', sans-serif;
  padding: 16px;
}
.text-decoration-none {
  text-decoration: none;
  color: inherit; 
}

.post-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.post-item {
  text-decoration: none;
  color: inherit;
  /* max-height: 400px; */
}

.post-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  height:auto;
}
.text-gray{
  font-size: 0.77em;
  color:gray
}

.post-card:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.post-image {
  width: 100%;
  object-fit: cover;
  max-height: 300px;
  border-radius: 2px;
}

.post-image.landscape {
  aspect-ratio: 16 / 9; /* Landscape images take full width */
}

.post-image.portrait {
  aspect-ratio: 3 / 4; /* Portrait images fit within grid cells */
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto; /* Allow flexibility */
  background-color: #f0f0f0;
}



.post-title {
  font-size: 1rem; 
  margin-bottom: 0.15rem; 
}

.post-description {
  font-size: 0.75rem; /* Smaller font for description */
  color: #555;
}

.post-footer {
  display: flex;
  justify-content: space-between;
}

.author-info {
  display: flex;
  align-items: center; /* Align image and text vertically */
}

.author-image {
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  margin-right: 10px; 
}
