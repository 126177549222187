/* navbar.css */

/* General styling for navigation container */
.navigation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  /* Styling for nav links */
  .nav-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    margin: 0.5rem 0;
  }

  
  .nav-link svg {
    margin-right: 0.5rem;
  }

.nav-link:hover {
  color: yellow;
}

.nav-link svg {
  color: currentColor; 
}

  
  /* Side nav for large screens */
  @media (min-width: 992px) {
    .navigation-container {
      width: 250px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #012901;
      border-right: 1px solid #ddd;
    }
  
    .nav-content {
      flex-direction: column;
      gap: 1rem;
    }
  }
  
  /* Bottom tab for small screens */
  @media (max-width: 991px) {
    .navigation-container {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: #012901;
      border-top: 1px solid #ddd;
    }
  
    .nav-content {
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
    }
  
    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.875rem;
    }
  
    .nav-link span {
      display: none; /* Hide text on smaller screens for a cleaner look */
    }
  }
  